/**
 * Extracts only selected properties of a type
 *
 * @param properties keys of the type including a boolean value
 *
 * @param options `extractUntyped` can be set to also return
 * properties that are not passed with the type
 *
 * @returns A function that can be configured and called
 * to get subset of an object
 *
 * @example
 * ```
 * interface TypedUser {
 *  name: string;
 *  id: number;
 * }
 *
 * const getAdditionalProperties = extractProperties<TypedUser>(
 *  {
 *    name: false,
 *    id: false,
 *  },
 *  { extractUntyped: true }
 * );
 *
 *  const user = {
 *  name: 'John Doe',
 *  id: 33,
 *  language: 'en',
 * };
 *
 * getAdditionalProperties(user) // === { language: 'en' }
 * ```
 */
export function extractProperties<T extends object>(
  properties: Record<keyof T, boolean>,
  options?: { extractUntyped: boolean },
) {
  return function <TActual extends T>(value: TActual): Record<string, any> {
    // extract actual properties of type
    const returnableProperties = Object.keys(properties) as Array<keyof T>;
    const filtered = returnableProperties.filter(
      (propKey) => properties[propKey] === true,
    );
    const result = {} as Record<string, any>;
    for (const property of filtered) {
      result[property as string] = value[property];
    }

    // extract additional properties
    if (options?.extractUntyped) {
      const filteredValues = Object.keys(value).filter(
        (actualValue) =>
          !(returnableProperties as Array<string>).includes(actualValue),
      );
      for (const property of filteredValues) {
        result[property] = value[property as keyof TActual];
      }
    }

    return result;
  };
}
