import { Pipe, PipeTransform } from '@angular/core';
import { ToValuePipeMapping } from './map-to-value.pipe';

export type BooleanValueMapping<Value> = ToValuePipeMapping<
  'true' | 'false',
  Value
>;
export type BooleanOrFallbackValueMapping<Value> = ToValuePipeMapping<
  'true' | 'false' | 'fallback',
  Value
>;

/**
 * Maps a boolean `input` to some other value.
 *
 * @example
 * {{ true | mapBool: {true: 'yes', false: 'no'} }}
 * // --> 'yes'
 */
@Pipe({
  name: 'mapBool',
})
export class MapBooleanToValuePipe implements PipeTransform {
  /**
   * Maps the given `input` to the corresponding value in
   * `mapping` for this `input`.
   *
   * @param input Input value
   * @param mapping Mapping
   *
   * @returns
   * Value that the `mapping` specified for the given `input`,
   * or `fallback` if none provided.
   */
  transform<TResult>(
    input: boolean,
    mapping: BooleanValueMapping<TResult>,
  ): TResult;
  transform<O, TResult>(
    input: boolean | O,
    mapping: BooleanOrFallbackValueMapping<TResult>,
  ): TResult;
  transform<TInput, TResult, TFallback>(
    input: boolean | TInput,
    mapping: BooleanValueMapping<TResult>,
    fallback: TFallback,
  ): TResult | TFallback;

  transform<TInput, TResult>(
    input: boolean | TInput,
    mapping: BooleanOrFallbackValueMapping<TResult>,
    fallback: unknown = EMPTY_FALLBACK,
  ): TResult | unknown {
    if (typeof input === 'boolean') {
      return input ? mapping.true : mapping.false;
    }

    return fallback === EMPTY_FALLBACK ? mapping.fallback : fallback;
  }
}

/** unique value to identify if a fallback was passed or not. */
const EMPTY_FALLBACK = Symbol('map-to-bool.empty');
