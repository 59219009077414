import {
  EnvironmentProviders,
  Provider,
  inject,
  makeEnvironmentProviders,
} from '@angular/core';
import { ProviderFeature } from '@fmnts/common';
import { LogBus } from './log-bus.service';
import { LogDriverManager } from './log-driver';
import { LogDriverFeatureKind } from './log-driver.provider';
import { LOG_SCOPE_TOKEN, ROOT_SCOPE, createLogScope } from './log-scope';
import {
  LOGGER_FACTORY_TOKEN,
  LogManager,
  LogMessageToLogEventMapper,
} from './logger-factory.service';

type LogFeatureKind = LogDriverFeatureKind;
type LogFeature<TKind extends LogFeatureKind> = ProviderFeature<TKind>;

/**
 * Configures logging and makes the `logger` feature available in
 * services and directives.
 *
 * @param features Set of features.
 */
export function provideLogging(
  ...features: LogFeature<LogFeatureKind>[]
): EnvironmentProviders {
  const providers: Provider[] = [
    { provide: LOG_SCOPE_TOKEN, useValue: ROOT_SCOPE },
    LogMessageToLogEventMapper,
    LogBus,
    LogDriverManager,
    LogManager,
    { provide: LOGGER_FACTORY_TOKEN, useExisting: LogManager },
  ];

  return makeEnvironmentProviders([
    ...providers,
    ...features.flatMap((f) => f.providers),
  ]);
}

/**
 * Creates a new child scope for logging.
 *
 * Any new loggers instantiated via `logger(...)` within this new scope
 * will use this scope by default when logging a new message.
 *
 * @example
 * export const appRoutes: Route[] = [
 * {...}, // other routes
 * {
 *    path: 'admin',
 *    providers: [provideLogScope({ name: 'admin' })],
 *    children: [...]
 * }
 */
export function provideLogScope(options: { name: string }): Provider {
  return [
    {
      provide: LOG_SCOPE_TOKEN,
      useFactory: () =>
        createLogScope(
          inject(LOG_SCOPE_TOKEN, { skipSelf: true }),
          options.name,
        ),
    },
  ];
}
