/**
 * Describes the severity of a log message.
 */
export enum LogLevel {
  Trace = 'trace',
  Debug = 'debug',
  Info = 'info',
  Warn = 'warn',
  Error = 'error',
}

/**
 * A log message describes what a logger should log.
 */
export interface LogMessage {
  /** Log message. */
  message: string;
  /** Severity of the log message. */
  level: LogLevel;
  /**
   * Category that the log message belongs to.
   * If omitted, the default category of the logger is used.
   */
  category?: string;
  /**
   * Scope that the log message belongs to.
   * If omitted, the default scope of the logger is used.
   */
  scope?: string;
  /** Additional data for the log message */
  data?: unknown[];
}

/**
 * A log event is created by a logger
 */
export interface LogEvent {
  /** Log message. */
  message: string;
  /** Severity of the log message. */
  level: LogLevel;
  /** Category that the log message belongs to. */
  category: string;
  /** Scope that the log message belongs to. */
  scope: string;
  /** Timestamp of the log event. */
  timestamp: Date;
  /** Name of the subsystem that was used to create the event. */
  subsystem: string;
  /** Additional data passed to the log message */
  data?: unknown[];
}

/**
 * Use it to log messages about your systems behavior.
 */
export interface Logger {
  /** Writes a log message. */
  log(message: LogMessage): void;
  /** Writes information about an error to the log. */
  error(message: string, ...data: unknown[]): void;
  /** Writes information about a warning to the log. */
  warn(message: string, ...data: unknown[]): void;
  /** Writes an informative message to the log. */
  info(message: string, ...data: unknown[]): void;
  /** Writes a debug message to the log. */
  debug(message: string, ...data: unknown[]): void;
  /** Writes a trace message to the log. */
  trace(message: string, ...data: unknown[]): void;
}

/** Formats a log event to a string. */
export type LogEventFormatFn = (log: LogEvent) => string;

/** Predicate function for filtering log events. */
export type LogEventPredicateFn = (log: LogEvent) => boolean;

/**
 * A log driver transports log events to another system.
 */
export interface LogDriver {
  /** Called whenever a log event should be written by the log driver. */
  write(message: LogEvent): void;
}

export interface LogMessageDefaults {
  /** Default category of the logger. */
  category?: string;
  /** Default Scope of the logger. */
  scope?: string;
}

export interface LoggerOptions extends LogMessageDefaults {
  /** Name of the subsystem logger. */
  name: string;
}
export interface LoggerFactory {
  getLogger(options: Readonly<LoggerOptions>): Logger;
}
