import { NgModule } from '@angular/core';
import { BypassSecurityTrustResourceUrlPipe } from './pipes/bypass-security-trust-resource-url.pipe';
import { BytePipe } from './pipes/byte.pipe';
import { DateRangePipe } from './pipes/date-range.pipe';
import { I18nDateRangePipe } from './pipes/i18n-date-range.pipe';
import { I18nTimeSpanPipe } from './pipes/i18n-time-span.pipe';
import { JoinPipe } from './pipes/join.pipe';
import { MapBooleanToValuePipe } from './pipes/map-bool-to-value.pipe';
import { MapToValuePipe } from './pipes/map-to-value.pipe';
import { PluckPipe } from './pipes/pluck.pipe';
import { TimeSpanPipe } from './pipes/time-span.pipe';

@NgModule({
  imports: [],
  declarations: [
    BypassSecurityTrustResourceUrlPipe,
    BytePipe,
    DateRangePipe,
    I18nDateRangePipe,
    I18nTimeSpanPipe,
    JoinPipe,
    MapBooleanToValuePipe,
    MapToValuePipe,
    PluckPipe,
    TimeSpanPipe,
  ],
  exports: [
    BypassSecurityTrustResourceUrlPipe,
    BytePipe,
    DateRangePipe,
    I18nDateRangePipe,
    I18nTimeSpanPipe,
    JoinPipe,
    MapBooleanToValuePipe,
    MapToValuePipe,
    PluckPipe,
    TimeSpanPipe,
  ],
})
export class FmntsCommonModule {}
