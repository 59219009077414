import { Provider } from '@angular/core';

/**
 * A general interface for a provider feature that can be distinguished
 * by its `kind`.
 */
export interface ProviderFeature<TKind> {
  /** feature kind. */
  kind: TKind;
  /** Providers for this feature. */
  providers: Provider[];
}

/**
 * Use to create a common setup for providing additional features for providers.
 *
 * @example
 * enum MyFeatureKind { A, B }
 *
 * interface MyFeature<TKind extends MyFeatureKind> = ProviderFeature<TKind>;
 * const { make: makeMyFeature } = providerFeature<MyFeatureKind>();
 *
 * export function provideMyFeature(
 *  ...features: MyFeature<MyFeatureKind>[]
 * ) {...}
 *
 * export function withA(): MyFeature<MyFeatureKind.A> {
 *  const providers = [...];
 *  return makeMyFeature(MyFeatureKind.A, providers)
 * }
 */
export function providerFeature<TKinds>(): {
  make<TKind extends TKinds>(
    this: void,
    kind: TKind,
    providers: Provider[],
  ): ProviderFeature<TKind>;
} {
  return {
    // We directly return the function to create the provider feature(s)
    // This allows us to reuse the same function again and again while
    // at the same time improving type safety.
    make: makeProviderFeature,
  };
}

function makeProviderFeature<TKind>(
  kind: TKind,
  providers: Provider[],
): ProviderFeature<TKind> {
  return {
    kind,
    providers,
  };
}
