import { isRecord } from '@fmnts/core';

type KeyType = string | number;

/**
 * @param path Path to property
 *
 * @returns
 * A function that when passed an object
 * extracts the value along the given
 * property `path`.
 *
 * @example
 * pluck(['a'])({a: 'b'}) // => 'b'
 */
export function pluck<K0 extends KeyType>(
  path: [K0],
): <T>(a: Record<K0, T>) => T;

/**
 * @param path Path to property
 *
 * @returns
 * A function that when passed an object
 * extracts the value along the given
 * property `path`.
 *
 * @example
 * pluck(['a', 'b'])(
 *  {a: {b: 'c'}}
 * ) // => 'c'
 */
export function pluck<
  // first prop
  K0 extends KeyType,
  K1 extends KeyType,
>(path: [K0, K1]): <T>(a: Record<K0, Record<K1, T>>) => T;

/**
 * @param path Path to property
 *
 * @returns
 * A function that when passed an object
 * extracts the value along the given
 * property `path`.
 *
 * @example
 * pluck(['a', 'b', 'c'])(
 *  {a: {b: {c: 'd'}}}
 * ) // => 'd'
 */
export function pluck<
  K0 extends KeyType,
  K1 extends KeyType,
  K2 extends KeyType,
>(path: [K0, K1, K2]): <T>(a: Record<K0, Record<K1, Record<K2, T>>>) => T;

export function pluck(
  path: KeyType[],
): (a: Record<KeyType, any>) => unknown | null;

export function pluck(
  path: KeyType[],
): (a: Record<KeyType, any>) => unknown | null {
  // Optimize performance based on how nested the path
  // to the property is.
  switch (path.length) {
    case 0:
      return (v) => v;
    case 1:
      return _pickSafe(path[0]);
    default:
      return (v) => _reduceValue(path, v);
  }
}

/**
 * Helper that recursively picks a property from a given value and returns it.
 */
function _reduceValue(path: KeyType[], value: unknown): unknown {
  if (!isRecord(value)) {
    return undefined;
  }

  const [currentPath, ...restPath] = path;
  const newValue = _pick(currentPath)(value);

  if (restPath.length === 0) {
    return newValue;
  }

  return _reduceValue(restPath, newValue);
}

/**
 * Picks a single property from a value if it is an object.
 *
 * @returns
 * Value of the property or `undefined`.
 */
function _pickSafe(property: KeyType): (value: unknown) => unknown | undefined {
  const _getPropertyValue = _pick(property);
  return (value) => (isRecord(value) ? _getPropertyValue(value) : undefined);
}

/** Picks a single property */
function _pick(property: KeyType): <T>(value: Record<KeyType, T>) => T {
  return ({ [property]: result }) => result;
}
