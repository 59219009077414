import {
  EnvironmentInjector,
  Injectable,
  InjectionToken,
  Injector,
  OnDestroy,
  Provider,
  Type,
  createEnvironmentInjector,
  inject,
  runInInjectionContext,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter } from 'rxjs';
import { LogBus } from './log-bus.service';
import { LogDriver, LogEvent, LogEventPredicateFn } from './log.model';

/**
 * Interface that holds the information needed to create a
 * log driver
 */
export interface LogDriverFactory {
  /** constructor for the log driver. */
  type: Type<LogDriver>;
  /** Additional providers */
  providers: Provider[];
}

/**
 * Multi provider token from which the log drivers are created.
 */
export const LOG_DRIVER_FACTORY_TOKEN = new InjectionToken<LogDriverFactory[]>(
  '@fmnts.common.log.log-driver-factories',
);

/**
 * Multi provider token for filtering log events for log drivers.
 */
export const LOG_FILTER_FNS_TOKEN = new InjectionToken<LogEventPredicateFn[]>(
  '@fmnts.common.log.log-event-filter-fn',
);

/**
 * Base implementation for a log driver.
 */
export abstract class AbstractLogDriver implements LogDriver {
  private readonly bus = inject(LogBus);
  private readonly filter = inject(LogEventFilter, { optional: true });

  constructor() {
    this.bus.events$
      .pipe(
        filter((log) => this.filter?.filter(log) ?? true),
        takeUntilDestroyed(),
      )
      .subscribe((event) => {
        this.write(event);
      });
  }

  abstract write(log: LogEvent): void;
}

/**
 * The log driver manager is responsible for creating the log drivers
 * for each level in the hierarchy.
 *
 * Each log driver get their own injector so that logging features
 * such as filtering and formatting can be reused and customized
 * for each log driver separately.
 */
@Injectable()
export class LogDriverManager implements OnDestroy {
  /** Parent injector for the log driver injectors. */
  private readonly _injector = inject(EnvironmentInjector);
  /** Holds all injectors for the log drivers. */
  private readonly _injectors = new Map<LogDriver, EnvironmentInjector>();

  constructor() {
    // Eagerly create all log drivers from the current level
    // by calling their factories.
    const driverFactories = inject(LOG_DRIVER_FACTORY_TOKEN, { self: true });
    for (const factory of driverFactories) {
      this.createLogDriver(factory);
    }
  }

  /**
   * Creates a new log driver from the given factory.
   *
   * @param factory Log Driver Factory.
   */
  private createLogDriver(factory: LogDriverFactory): void {
    const providers = factory.providers;
    const injector =
      providers.length > 0
        ? createEnvironmentInjector(
            [providers],
            this._injector,
            `LogDriver[${factory.type.name}]`,
          )
        : this._injector;

    const driver = runInInjectionContext(injector, () => inject(factory.type));
    this._injectors.set(driver, injector);
  }

  ngOnDestroy(): void {
    try {
      for (const injector of this._injectors.values()) {
        injector.destroy();
      }
    } finally {
      this._injectors.clear();
    }
  }
}

/**
 * Consolidates filtering for log drivers.
 */
@Injectable()
export class LogEventFilter {
  private readonly injector = inject(Injector);
  private chain: LogEventPredicateFn[] | null = null;

  filter(log: LogEvent): boolean {
    if (this.chain === null) {
      this.chain = [
        ...runInInjectionContext(
          this.injector,
          () =>
            inject(LOG_FILTER_FNS_TOKEN, { optional: true, self: true }) ?? [],
        ).map(chainedFilterFn(this.injector)),
      ];
    }

    for (const fn of this.chain) {
      if (!fn(log)) {
        return false;
      }
    }

    return true;
  }
}

function chainedFilterFn(injector: Injector) {
  return (filterFn: LogEventPredicateFn): LogEventPredicateFn =>
    (log) =>
      runInInjectionContext(injector, () => filterFn(log));
}
