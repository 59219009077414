import { inject } from '@angular/core';
import { LOG_SCOPE_TOKEN, createLogScope } from './log-scope';
import { Logger as ILogger, LoggerOptions } from './log.model';
import { LOGGER_FACTORY_TOKEN } from './logger-factory.service';

/**
 * Creates a new logger within the current log scope.
 * Must be called within an injection context.
 *
 * @param options Logger configuration
 */
export function logger(options: LoggerOptions): ILogger {
  const parentScope = inject(LOG_SCOPE_TOKEN);
  const scope = options.scope
    ? createLogScope(parentScope, options.scope)
    : parentScope;

  return inject(LOGGER_FACTORY_TOKEN).getLogger({
    ...options,
    scope,
  });
}
