import { Injectable, InjectionToken, inject } from '@angular/core';
import { AbstractLogDriver } from './log-driver';
import { LogEvent, LogEventFormatFn, LogLevel } from './log.model';

/**
 * Injection token for the web console.
 */
const CONSOLE_TOKEN = new InjectionToken('@fmnts.common.console', {
  providedIn: 'platform',
  factory: () => console,
});

/**
 * Logs log events on the web console.
 */
@Injectable()
export class ConsoleLogDriver extends AbstractLogDriver {
  private readonly console = inject(CONSOLE_TOKEN);
  private readonly formatter: LogEventFormatFn = format;

  override write(log: LogEvent): void {
    const args = this.format(log);
    switch (log.level) {
      case LogLevel.Error:
        this.console.error(...args);
        break;
      case LogLevel.Warn:
        this.console.warn(...args);
        break;
      case LogLevel.Info:
        this.console.info(...args);
        break;
      case LogLevel.Debug:
        this.console.debug(...args);
        break;
      case LogLevel.Trace:
        this.console.trace(...args);
        break;
      default:
        this.console.log(...args);
        break;
    }
  }

  private format(log: LogEvent): unknown[] {
    const msg = this.formatter(log);
    if (!log.data) {
      return [msg];
    }
    return [msg, ...log.data];
  }
}

function format({ message, subsystem }: LogEvent) {
  return `[${subsystem}] ${message}`;
}
