import {
  EnvironmentProviders,
  Type,
  inject,
  makeEnvironmentProviders,
} from '@angular/core';
import { ProviderFeature, providerFeature } from '@fmnts/common';
import {
  LOG_DRIVER_FACTORY_TOKEN,
  LOG_FILTER_FNS_TOKEN,
  LogDriverFactory,
  LogEventFilter,
} from './log-driver';
import { LogDriver, LogEventPredicateFn } from './log.model';

export enum LogDriverFeatureKind {
  Filter,
}

export type LogDriverFeature<TKind extends LogDriverFeatureKind> =
  ProviderFeature<TKind>;

const { make: makeLogDriverFeature } = providerFeature<LogDriverFeatureKind>();

/**
 * Setup providers for the log driver.
 *
 * @param logDriverCtor Log Driver constructor.
 * @param features Set of features.
 *
 * @see {@link withEventFilters}
 * @see {@link withEventFiltersFromParent}
 */
export function provideLogDriver<T extends LogDriver>(
  logDriverCtor: Type<T>,
  ...features: LogDriverFeature<LogDriverFeatureKind>[]
): EnvironmentProviders {
  const providers = features.flatMap((f) => f.providers);
  const logDriverFactory: LogDriverFactory = {
    providers: [logDriverCtor, ...providers],
    type: logDriverCtor,
  };

  return makeEnvironmentProviders([
    {
      provide: LOG_DRIVER_FACTORY_TOKEN,
      useValue: logDriverFactory,
      multi: true,
    },
  ]);
}

/**
 * Applies filters for log driver(s).
 *
 * @param predicates Predicate functions
 */
export function withEventFilters(
  ...predicates: LogEventPredicateFn[]
): LogDriverFeature<LogDriverFeatureKind.Filter> {
  return makeLogDriverFeature(LogDriverFeatureKind.Filter, [
    LogEventFilter,
    ...predicates.map((fn) => ({
      provide: LOG_FILTER_FNS_TOKEN,
      useValue: fn,
      multi: true,
    })),
  ]);
}

/**
 * Add this feature to inherit the log event filters from the parent.
 */
export function withEventFiltersFromParent(): LogDriverFeature<LogDriverFeatureKind.Filter> {
  return makeLogDriverFeature(LogDriverFeatureKind.Filter, [
    {
      provide: LOG_FILTER_FNS_TOKEN,
      multi: true,
      useFactory: (): LogEventPredicateFn => {
        const filter = inject(LogEventFilter, { skipSelf: true });
        return (event) => filter?.filter(event);
      },
    },
  ]);
}
