import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LogEvent } from './log.model';

/**
 * The `LogBus` distributes log events to subscribers.
 */
@Injectable()
export class LogBus {
  private readonly events = new Subject<LogEvent>();

  /** Stream of log events. */
  readonly events$ = this.events.asObservable();

  /**
   * Dispatches a new log event on the log bus.
   *
   * @param event
   */
  dispatch(event: LogEvent): void {
    this.events.next(event);
  }
}
