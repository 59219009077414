const REDACTED = '<<redacted>>';

/** Symbol for log data that was redacted for privacy reasons. */
const REDACTED_LOG_DATA = Symbol('log-redacted');
export type RedactedLogData = typeof REDACTED_LOG_DATA;

/** Creates redacted log data. */
export function redactLogData(): RedactedLogData {
  return REDACTED_LOG_DATA;
}

/** Type guard to see if something is redacted log data. */
export function isRedactedLogData(d: unknown): d is RedactedLogData {
  return d === REDACTED_LOG_DATA;
}

/**
 * Replaces the redacted symbol with a string
 *
 * @param data Data to replace
 */
export function replaceRedactSymbol(data: unknown): unknown {
  return isRedactedLogData(data) ? REDACTED : data;
}
