import { InjectionToken } from '@angular/core';

export const ROOT_SCOPE = '<root>' as const;

/**
 * DI token for the current log scope.
 */
export const LOG_SCOPE_TOKEN = new InjectionToken<string>(
  '@fmnts.common.log.log-scope',
  {
    providedIn: 'platform',
    factory: () => ROOT_SCOPE,
  },
);

/**
 * Creates a new log scope within the parent scope.
 *
 * @param parent Parent scope
 * @param name Name of the new scope
 *
 * @returns
 * New log scope within the parent scope.
 */
export function createLogScope(parent: string, name: string): string {
  return parent === ROOT_SCOPE ? name : `${parent}.${name}`;
}
