type KeyType = string | number;
type Reducer<T> = (acc: T, cur: KeyType) => any;

const defaultReducer: Reducer<any> = (
  acc: Record<KeyType, unknown>,
  cur: KeyType,
) => (typeof acc === 'object' && acc !== null && cur in acc ? acc[cur] : null);

/**
 * @param obj
 * @param delimiter
 *
 * @returns
 * A function that can be called with a path that returns the
 * value at the resolved path.
 */
export function createPathGetter<T extends Record<KeyType, any> = any>(
  obj: T,
  delimiter: string,
  reducer: Reducer<T> = defaultReducer,
) {
  return (path: string | KeyType[]): any => {
    if (path === null) {
      return null;
    }

    // Transform path to an array
    if (!Array.isArray(path)) {
      path = path.split(delimiter);
    }

    if (path.length === 0) {
      return null;
    }

    return path.reduce(reducer, obj);
  };
}
