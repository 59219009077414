import { Injectable, InjectionToken, inject } from '@angular/core';
import { LogBus } from './log-bus.service';
import { LogDriverManager } from './log-driver';
import { replaceRedactSymbol } from './log-privacy';
import { LOG_SCOPE_TOKEN } from './log-scope';
import {
  Logger as ILogger,
  LoggerFactory as ILoggerFactory,
  LogEvent,
  LogLevel,
  LogMessage,
  LogMessageDefaults,
  LoggerOptions,
} from './log.model';

/**
 * DI token for logger factory.
 */
export const LOGGER_FACTORY_TOKEN = new InjectionToken<ILoggerFactory>(
  '@fmnts.common.log.logger-factory',
);

interface LogMessageDispatcher {
  dispatch(subsystem: string, event: LogMessage): void;
}

class Logger implements ILogger {
  constructor(
    /** Name of the logger. */
    private readonly name: string,
    /** Writes a message to the log. */
    private readonly _dispatcher: LogMessageDispatcher,
    /** Defaults for the log message */
    private readonly messageDefaults?: Readonly<LogMessageDefaults>,
  ) {}

  log(message: LogMessage) {
    this._dispatcher.dispatch(this.name, {
      ...this.messageDefaults,
      ...message,
    });
  }

  error(message: string, ...data: unknown[]) {
    this.log({ level: LogLevel.Error, message, data });
  }
  warn(message: string, ...data: unknown[]) {
    this.log({ level: LogLevel.Warn, message, data });
  }
  info(message: string, ...data: unknown[]) {
    this.log({ level: LogLevel.Info, message, data });
  }
  debug(message: string, ...data: unknown[]) {
    this.log({ level: LogLevel.Debug, message, data });
  }
  trace(message: string, ...data: unknown[]) {
    this.log({ level: LogLevel.Trace, message, data });
  }
}

/**
 * The `LogManager` is responsible for managing the different
 * logs of an application and distribute their log events
 * via the log bus.
 */
@Injectable()
export class LogManager implements LogMessageDispatcher, ILoggerFactory {
  private readonly bus = inject(LogBus);
  private readonly mapper = inject(LogMessageToLogEventMapper);
  // Inject the log driver manager to eagerly create all provided log drivers.
  private readonly driver = inject(LogDriverManager);

  /**
   * Get a logger.
   *
   * To create a new logger prefer using the {@link logger} function.
   */
  getLogger({ name, ...messageDefaults }: Readonly<LoggerOptions>): ILogger {
    return new Logger(name, this, this.mapper.cleanupDefaults(messageDefaults));
  }

  dispatch(name: string, message: LogMessage): void {
    this.bus.dispatch(this.mapper.mapTo(name, message));
  }
}

/**
 * Maps a log message to a log event.
 */
@Injectable()
export class LogMessageToLogEventMapper {
  /** default scope. */
  private readonly scope = inject(LOG_SCOPE_TOKEN);
  /** default category. */
  private readonly category = 'default';

  mapTo: (subsystem: string, msg: LogMessage) => LogEvent = (
    subsystem,
    msg,
  ) => {
    const logEvent: LogEvent = {
      timestamp: new Date(),
      subsystem,
      ...msg,
      category: msg.category ?? this.category,
      scope: msg.scope ?? this.scope,
    };

    if (msg.data && msg.data.length > 0) {
      logEvent.data = msg.data.map(replaceRedactSymbol);
    }

    return logEvent;
  };

  /**
   * Cleanup log message default values that have equivalent values
   * to the defaults that this mapper would add.
   *
   * This keeps memory footprint of loggers smaller.
   */
  cleanupDefaults(
    defaults: LogMessageDefaults,
  ): LogMessageDefaults | undefined {
    const generalDefaults = this.getDefaults();

    for (const key of Object.keys(defaults) as (keyof LogMessageDefaults)[]) {
      if (
        defaults[key] === undefined ||
        defaults[key] === null ||
        generalDefaults[key] === defaults[key]
      ) {
        delete defaults[key];
      }
    }

    return Object.keys(defaults).length === 0 ? undefined : defaults;
  }

  private getDefaults(): LogMessageDefaults {
    return {
      category: this.category,
      scope: this.scope,
    };
  }
}
