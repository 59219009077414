import { isString } from '@fmnts/core';

/**
 * Utility function for getting css classnames
 *
 * @param items css classes
 *
 * @returns
 * A string of combined css classes
 */
export function classnames(items: unknown[]): string {
  return items.filter((i) => isString(i) && !!i).join(' ');
}
